@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500;9..40,600;9..40,700&family=Inter:wght@300;400;500;600;700;800&family=Playfair+Display:wght@400;500;600&display=swap");

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

:root {
  --header-lvl-2-color: rgba(98, 71, 54, 1);
  --hr-color: rgba(236, 89, 41, 1);
}

* {
  transition: all ease-in-out 400ms;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header-lvl-2 {
  color: var(--header-lvl-2-color);
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.25rem;
  letter-spacing: 0.01rem;
}

.hr-line {
  display: inline-block;
  background-color: var(--hr-color);
  width: 3.125rem;
  height: 0.25rem;
  bottom: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.program,
.heading {
  font-family: "Playfair Display", serif;
}

p,
li {
  font-family: "DM Sans", sans-serif;
}

@layer components {
  .program {
    @apply tracking-wide font-light capitalize;
  }
  .time {
    @apply sm:text-lg font-semibold;
    font-family: "DM Sans", sans-serif;
  }
  .svc {
    @apply text-left py-2 sm:py-0;
  }
  .clergy {
    @apply flex flex-col justify-center p-3 items-center gap-4 bg-[#F8F8F8] ring-1 ring-inset ring-neutral-100 rounded-lg;
  }
  .social-links {
    @apply bg-black bg-none duration-500 grid place-items-center size-8 rounded-full;
  }
  .gallery___btn{
    @apply flex gap-4 justify-center text-sm *:px-4 *:py-2 *:rounded-lg *:bg-transparent *:text-primary *:ring-1 *:ring-inset *:ring-primary *:cursor-pointer
  }
  .gallery___btn .active{
    @apply !bg-primary !text-white
  }
}


.gall:hover .bgi {
  display: block !important;
}

.gall:hover .imgx {
  scale: 1.1;
}

.custom-gradient {
  background: linear-gradient(
      92.53deg,
      #000000 0%,
      #a84f33 44.05%,
      #000000 97.88%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
}

.custom-box-shadow-gradient {
  background: linear-gradient(85.45deg, #000000 99.83%, #fc7d1d 99.84%);
  box-shadow: -0.375px -0.375px 0.75px 0px rgba(0, 0, 0, 0.16) inset,
    0px 0.75px 1.125px 0px rgba(255, 255, 255, 1) inset,
    -0.375px -1.5px 2.25px 0px rgba(0, 0, 0, 0.12) inset,
    0px -0.1875px 0.75px 0px rgba(0, 0, 0, 0.06),
    0px 1.5px 6px 0px rgba(0, 0, 0, 0.06);
}
.wyt:hover .yt {
  color: rgb(185 28 28);
}

.wfb:hover .fb {
  color: rgb(3 105 161);
}

.gallery___img:nth-child(2) {
  grid-column: span 2 / span 2;
}
